import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import * as React from "react"

import { cn } from "@/app/utils/misc"

export type CheckboxProps = Omit<
	React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
	`type`
> & {
	type?: string
}

const Checkbox = React.forwardRef<
	React.ElementRef<typeof CheckboxPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, onClick, ...props }, ref) => {
	return (
		<CheckboxPrimitive.Root
			ref={ref}
			className={cn(
				`text-white peer h-4 w-4 shrink-0 rounded-full border border-gray-400 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-blue-100 data-[state=checked]:border-none`,
				className,
			)}
			{...props}
		>
			<CheckboxPrimitive.Indicator
				className={cn(`flex items-center justify-center`)}
			>
				<svg viewBox="0 0 8 8" height={14}>
					<title>Check</title>
					<path
						d="M1,4 L3,6 L7,2"
						stroke="currentcolor"
						strokeWidth="1"
						fill="none"
						stroke-linecap="round"
					/>
				</svg>
			</CheckboxPrimitive.Indicator>
		</CheckboxPrimitive.Root>
	)
})
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
